
.jls-switch-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: $switch-wrapper-max-width;
    
    .jls-switch-label {

      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: $size-m;
      
      .jls-switch {
        position: relative;
        display: inline-block;
        width: $switch-width;
        height: $switch-height;
    
        /* Hide default HTML checkbox */
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
    
        /* The slider */
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $grey-20;
          -webkit-transition: .4s;
          transition: .4s;
        }
    
        .slider:before {
          position: absolute;
          content: "";
          height: $switch-dot-size;
          width: $switch-dot-size;
          left: $switch-dot-left;
          bottom: $switch-dot-bottom;
          background-color: $jls-black;
          -webkit-transition: .3s;
          transition: .3s;
          box-shadow: 1px 1px 1px 0 rgba(42, 55, 63, 0.6);
        }
    
        input:checked + .slider {
          background-color: $jls-green;
        }
    
        input:focus + .slider {
          box-shadow: 0 0 1px $jls-green;
        }
    
        input:checked + .slider:before {
          -webkit-transform: translateX($size-m);
          -ms-transform: translateX($size-m);
          transform: translateX($size-m);
          background-color: $jls-black;
        }
    
        input:disabled + .slider {
          opacity: 0.2;
        }
    
        input:disabled + .slider:before {
          opacity: 0.2;
          background-color: $jls-black;
        }
    
        /* Rounded sliders */
        .slider.round {
          border-radius: $switch-dot-radius;
        }
    
        .slider.round:before {
          border-radius: 50%;
        }
    }
  }

  .jls-switch-text {
    width: fit-content;
    font-size: $size-m-m-l;
    font-weight: 900;
    position: relative;
    top: 1px;
    user-select: none;
  }

}
