.checkbox-with-text {
  .jls-checkbox {
    .input-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      input[type=checkbox] {
        width: rem(24px);
        height: rem(24px);
        // also minimum dimensions to prevent squishing for multiline labels
        min-width: rem(24px);
        min-height: rem(24px);

        &:checked {
          accent-color: $white;
          border-color: $jls-black;
        }
      }

      input[type=checkbox].error {
        outline: rem(2px) solid $red;
      }
    }
    .checkbox-error-container {
      font-size: rem(12px);
      line-height: 1.66;
      color: $red;
      position: absolute; // prevents visual shift up/down uf text below when error toggled
    }
  }
}
