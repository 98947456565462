.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $jls-veryblack;
  min-height: $cookie-banner-min-height;
  height: fit-content;
  z-index: $cookie-banner-z-index;
  border-top: 1px solid $grey-10;

  &.hidden {
    display: none;
  }
}

.cookie-banner-wrapper {
  max-width: $page-max-width;
  margin: 0 auto;
  padding: $size-l $cookie-banner-padding-x;

  .button {
    min-width: unset;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    padding: $size-l;

    .button {
      min-width: initial;
      width: initial;
    }
  }
}

.cookie-banner-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &-text {
    max-width: $cookie-banner-text-max-width;
    line-height: 1.33;
    .cookie-text-mobile {
      max-height: 65px;
      transition: max-height 0.5s ease;

      }
      .cookie-text-desktop {
        max-height: 500px;
        transition: max-height 0.5s ease;

    }
    #show-more {
      text-decoration: underline;
    }
  }

  &-cta {
    display: flex;
    flex-direction: column;
  }

  .button-container-button {
    width: 120px;
  }
}

.cookie-modal-wrapper {
  display: none;

  &.show {
    display: block;
  }
}

.cookie-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(42, 55, 63, 0.6);
  z-index: $cookie-modal-overlay-z-index;
}

.cookie-modal {
  position: fixed;
  z-index: $cookie-modal-z-index;
  border-radius: 2px;
  box-shadow: $cookie-box-shadow;
  width: calc(100% - 2 * #{$size-l});
  top: $size-l;
  left: $size-l;
  bottom: $size-l;
  overflow-y: auto;
  height: calc(100vh - 2 * #{$size-l});
  max-height: $cookie-modal-mobile-max-height;

  @media screen and (min-width: 768px) {
    max-width: $cookie-modal-max-width;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: $cookie-modal-max-height;
  }

  &-close {
    background-image: var(--close-white);
    width: $size-l;
    height: $size-l;
    background-size: $size-l;
    position: absolute;
    top: $size-l;
    right: $size-l;
    cursor: pointer;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
    padding: $cookie-modal-content-padding-mobile;
    @media screen and (min-width: 768px) {
      padding: $cookie-modal-content-padding-desktop;
    }

    &-selections {
      margin-top: $cookie-modal-selections-margin-top-mobile;
      width: 100%;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: $size-xxl;
      }

      .jls-switch-wrapper {
        &:nth-child(even) {
          margin-left: unset;
          margin-top: $size-l;
          @media screen and (min-width: 768px) {
            margin-top: unset;
            margin-left: $size-l;
          }
        }
      }
    }

    &-cta {
      margin-top: $cookie-modal-cta-margin-top-mobile;
      display: flex;
      flex-direction: column;
      width: 100%;


      @media screen and (min-width: 768px) {
        flex-direction: row;
        margin-top: $size-xxl;
        justify-content: flex-end;

      }
    }
  }
}

.cookie-modal-button {
  padding-top: $size-default;
  padding-bottom: $size-ll;
  width: 100%;
}
