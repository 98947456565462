@import "~sass-rem";

$page-max-width: rem(1536px);

$red: #eb525c;
$white: #ffffff;
$outline-grey: #d5d7d9;
$grey-60: #778187;
$grey-100: #2a373f;
$grey-40: #939ca1;
$grey-20: #C5CDE2;
$grey-10: #D9D9D9;
$jls-black: #111111;
$jls-veryblack: #000000;
$jls-green: #00EAB5;
$jls-red: #D90570;

// input field
$input-field-width: rem(300px);
$input-field-height: rem(48px);
$input-field-border-size: rem(2px);
$input-field-padding-right: rem(48px);
$input-field-padding-left: rem(16px);
$input-field-currency-padding-right: rem(16px);
$input-field-label-translate-base: rem(13px);
$input-field-label-translate-move: rem(-8px);
$input-field-label-transition: 0.2s;
$input-field-label-padding: 0 4px 0 4px;
$input-field-disabled-opacity: 0.4;
$input-field-datetime-left-button-min-width: rem(115px);
$input-field-datetime-right-button-min-width: rem(115px);
$input-field-icon-size: rem(20px);
$input-field-icon-right-pos: rem(16px);
$input-field-icon-top-pos: rem(14px);
$input-field-arrow-icon-width: rem(19px);
$input-field-arrow-icon-height: rem(12px);
$input-field-arrow-icon-right-pos: rem(16px);
$input-field-arrow-icon-top-pos: rem(19px);

$input-search-icon-size: rem(20px);
$input-search-icon-top: rem(14px);
$input-search-icon-right: rem(14px);

$size-m: rem(16px);
$size-m-m-l: rem(18px);
$size-default: rem(24px);
$size-l: rem(32px);
$size-ll: rem(40px);
$size-xl: rem(48px);
$size-xxl: rem(56px);

$fs-m-l: rem(18px);

// Cookie
$cookie-banner-z-index: 20000;
$cookie-banner-min-height: rem(119px);
$cookie-banner-padding-x: rem(64px);
$cookie-banner-text-max-width: rem(845px);
$cookie-modal-overlay-z-index: 20001;
$cookie-modal-z-index: 20002;
$cookie-shadow: rgba(42, 55, 63, 0.06);
$cookie-box-shadow: rem(2px) rem(4px) rem(40px) 0 $cookie-shadow;
$cookie-modal-max-width: rem(1176px);
$cookie-modal-padding: rem(80px) 0;
$cookie-modal-title-max-width: rem(679px);
$cookie-modal-switch-margin-bottom: rem(20px);
$cookie-modal-content-padding-mobile: rem(75px) $size-l $size-l $size-l;
$cookie-modal-content-padding-desktop: $size-l $size-l $size-l $size-xl;
$cookie-modal-selections-margin-top-mobile: rem(40px);
$cookie-modal-cta-margin-top-mobile: rem(40px);
$cookie-modal-mobile-max-height: rem(808px);
$cookie-modal-max-height: rem(652px);

// FabOverlay
$fab-modal-overlay-z-index: 20001;
$fab-modal-z-index: 20002;
$fab-shadow: rgba(42, 55, 63, 0.06);
$fab-box-shadow: rem(2px) rem(4px) rem(40px) 0 $fab-shadow;
$fab-icon-height: 20px;
$fab-halo-color: rgba(154, 171, 196, 0.5);
$fab-halo-z-index: 2000;
$fab-icon-z-index: 2001;
$fab-modal-max-width: 450px; //rem(1176px);
$fab-modal-mobile-max-height: rem(808px);
$fab-modal-max-height: rem(1012px);
$fab-modal-margin: rem(15px);

// Switch
$switch-wrapper-max-width: rem(510px);
$switch-height: rem(24px);
$switch-width: rem(40px);
$switch-dot-size: rem(18px);
$switch-dot-left: rem(3px);
$switch-dot-bottom: rem(3px);
$switch-dot-radius: rem(34px);
$switch-tooltip-size: rem(18px);
$switch-tooltip-top: rem(1px);
$switch-mobile-tooltip-top: rem(20px);
$switch-web-tooltip-max-width: rem(338px);
$switch-mobile-tooltip-padding: rem(51px) $size-l $size-l $size-l;
$switch-mobile-tooltip-max-width: rem(338px);
$switch-tooltip-max-width: rem(338px);
$switch-tooltip-top: rem(40px);
