/*set background color en text color when browser autocomplete input fields*/
input:-webkit-autofill, textarea:-webkit-autofill,
input:-webkit-autofill:hover, textarea:-webkit-autofill:hover,
input:-webkit-autofill:focus, textarea:-webkit-autofill:focus,
input:-webkit-autofill:active, textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $jls-black inset !important;
  -webkit-text-fill-color: $white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-input {
  display: inline-flex;
  position: relative;
  transition: width 0.2s ease-in-out;

  &.currency-input {
    input {
      display: none;
    }

    .div-currency-input {
      display: flex;
    }

    &.editing {
      input {
        display: flex;
      }

      .div-currency-input {
        display: none;
      }
    }
  }

  &:not(.light-input) {
    input, textarea {
      border: $input-field-border-size solid $outline-grey;
      caret-color: $white;
      background-color: $jls-black;
    }

    &.label-top {
      .move-up {
        background-color: $jls-black;
        @extend .text-small-white;
      }
    }

    .input-label {
      @extend .text-normal-white;
    }
  }

  &.light-input {
    input, textarea {
      border: $input-field-border-size solid $jls-black;
      caret-color: $jls-black;
      background-color: $white;
      @extend .text-normal-black;
    }

    &.label-top {
      .move-up {
        background-color: $white;
        @extend .text-normal-black;
      }
    }

    .input-label {
      @extend .text-normal-black;
    }
  }

  &.label-top {
    flex-direction: column;

    .input-label {
      pointer-events: none;
      position: absolute;
      transition: all $input-field-label-transition ease-in;
      transform: translateY($input-field-label-translate-base);
      margin-left: $input-field-padding-left;

      &.disabled {
        opacity: $input-field-disabled-opacity;
      }

      &.label-error {
        color: $red;
      }
    }

    .move-up {
      transform: translateY($input-field-label-translate-move);
      padding: $input-field-label-padding;
    }
  }

  .input-error-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .input-container {
    display: inline-flex;
    position: relative;
  }

  input {
    padding-right: $input-field-padding-right;
    padding-left: $input-field-padding-left;
    height: $input-field-height;
  }

  textarea {
    padding: $size-default $size-m;
    resize: none;
  }

  input, textarea {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:disabled {
      opacity: $input-field-disabled-opacity;
      background-color: $jls-black;
      color: $grey-60;
    }

    &.dark {
      border: $input-field-border-size solid $grey-100;
    }

    &.transparent {
      background-color: transparent;
    }

    &.input-error {
      border: $input-field-border-size solid $red;
    }

    &:focus:not(.input-error) {
      border: $input-field-border-size solid $grey-40;
    }
  }

  .error-container {
    position: absolute;
    @extend .text-small-red;
    bottom: rem(-20px);

    .hidden {
      display: none;
    }
  }
}

.fc-form-input {
  width: 100%;
}

.text-normal {
  font-size: rem(18px);
  line-height: 1.33;
}

.text-small {
  font-size: rem(12px);
  line-height: 1.66;
}

.text-normal-black {
  @extend .text-normal;
  color: $jls-black;
}

.text-normal-white {
  @extend .text-normal;
  color: $white;
}

.text-small-white {
  @extend .text-small;
  color: $white;
}

.text-small-red {
  @extend .text-small;
  color: $red;
}

