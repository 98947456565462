#contact-modal-wrapper {
  &:not(&.show) {
    .contact-modal-overlay {
      display: none;
    }
    .contact-modal {
      // With transition-left
      left: 200vw;
    }
  }
}

#contact-form-modal-iframe {
  height: 100%;
  width: 100%;

  @media (min-width: 768px) {
    width: $fab-modal-max-width;
  }
}

.contact-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(42, 55, 63, 0.6);
  z-index: $fab-modal-overlay-z-index;
}

.contact-modal {
  position: fixed;
  z-index: $fab-modal-z-index;
  border: 4px solid $jls-black;
  box-shadow: $fab-box-shadow;
  overflow-y: auto;
  inset: 0;
  height: 100vh;
  width: 100vw;
  max-width: 100%;

  @media (min-width: 768px) {
    left: calc(100vw - ($fab-modal-max-width / 2) - $fab-modal-margin);
    bottom: $size-l;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: min(calc(100vh - 2 * $fab-modal-margin), $fab-modal-max-height);
    height: 100%;
    max-width: $fab-modal-max-width;
    width: $fab-modal-max-width;
  }

  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: $size-xl;
    height: $size-xl;
    cursor: pointer;
    background: $jls-black;
    top: $size-m;
    right: $size-l;
    left: unset;

    @media (min-width: 768px) {
      right: unset;
      left: $size-l;
    }

    &-icon {
      width: $size-m;
      height: $size-m;
      background-image: var(--close-white);
      background-size: $size-m;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
  }
}

.contact-cta {
  display: flex;
  justify-content: center;
  align-items: center;

  &-halo {
    position: absolute;
    width: inherit;
    height: inherit;
    z-index: $fab-halo-z-index;
    background-color: $fab-halo-color;
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: $fab-icon-z-index;

    &-closed,
    &-opened {
      position: absolute;
    }

    &-opened {
      // Overlaps the envelope with the closed envelope.
      top: calc($fab-icon-height / 2);
    }

    &:hover > &-closed {
      opacity: 0;
    }

    &:hover > &-opened {
      opacity: 1;
    }
  }
}
