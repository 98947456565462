@import "./tailwind";
@import "./fonts";
@import "./variables";
@import "./inputFields";
@import "./cookie";
@import "./switch";
@import "./checkbox-with-text";
@import "./toggle-list";
@import "./fab-contact";


html,
body {

  &.disable-scroll {
    overflow-y: hidden;
    margin: 0;
    height: 100%;
  }
}
// Every link/a-tag is animated, unless you set the class "no-animate"
a:not(.no-animate) {
  transition: color 0.2s ease;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 5px;
    bottom: -0.5rem;
    left: 0;
    transform-origin: bottom left;


    -webkit-transition: width 0.2s ease, color 0.2s ease;
    -moz-transition: width 0.2s ease, color 0.2s ease;
    -o-transition: width 0.2s ease, color 0.2s ease;
    -ms-transition: width 0.2s ease, color 0.2s ease;
    transition: width 0.2s ease, color 0.2s ease;
  }

  // If "anim-preview" class is set, the animated line is already "previewed" to some extend
  &.anim-preview:after {
    width: 65px;
    left: 0;
  }
  &.anim-preview:hover:after {
    width: 100%;
  }

  // Link having white background is animated with nebula
  &.animate-white:after, &.animate-white.anim-preview:hover:after {
    background-color: $jls-red;
  }
  &.animate-white:hover {
    color: $jls-red;
  }
  &.animate-white.anim-preview:after {
    background-color: $jls-veryblack;
  }

  // Link having white background is animated with kryptonite
  &.animate-black:after, &.animate-black.anim-preview:hover:after {
    background-color: $jls-green;
  }
  &.animate-black:hover {
    color: $jls-green;
  }
  &.animate-black.anim-preview:after {
    background-color: $white;
  }

  &:hover:after{
    // we cannot use scaleX transform as in some cases we start with a fixed width
    width:100%;
  }
}
