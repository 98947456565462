/*Navigation: Prevents Layout-Change On-Hover*/
.nav-item {
  line-height: 1;
}
.nav-item::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

/*Blog/Reference Circle Animation: add a minimal-height, white background horizontal strip to prevent the background image
to clip/show ever so slightly below the white drop shadow (the white cover with the circular opening that changes
size when scrolling) of the reference-circle class*/
.overlap-cover {
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -1px;
  background-color: white;
}

/*Rich Text Component: Rich Text Styling*/
.rte ul {
  list-style: disc outside;
  margin-left: 1em;
}
.rte ol {
  list-style: decimal outside;
  margin-left: 1em;
}

/*Teaser Component: Slider w/ Opacity*/
.black-slider {
  background: rgba(0, 0, 0, 0.2);
}
.white-slider {
  background: rgba(255, 255, 255, 0.2);
}

/* Teaser Component: anchor to jump to reference site through click on image*/
.teaser-card-image-anchor {
  height: 100%;
  width: 100%;
  position: absolute;
  visibility: hidden;
  cursor: pointer;
}
/* Teaser Component: hide all anchors except the one in first place, else would disable ability to "scroll" through cards
 by clicking on cards in the background*/
.teaser-card[data-card-place="1"] .teaser-card-image-anchor {
  visibility: visible;
}
/* Teaser Component: enable clicking through card title */
.teaser-card[data-card-place="1"] .card-text {
  pointer-events: none;
  cursor: pointer;
}

/*Header Video Component: Hides Play Button on Safari, Chrome (https://jsfiddle.net/dawo42eu/)*/
.header-loop-video::-webkit-media-controls,
.header-loop-video::-webkit-media-controls-panel,
.header-loop-video::-webkit-media-controls-play-button,
.header-loop-video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

/*Swiperjs css*/
.product-slider-scrollbar {
  background-color: rgba(255, 255, 255, 0.15) !important;
  height: 2px !important;
  max-width: 400px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

@media screen and (max-width: 768px) {
  .product-slider-scrollbar {
    /* background-color: rgba(255, 255, 255, 0.15) !important; */
    /* height: 2px !important; */
    max-width: unset !important;
    left: 32px !important;
    transform: unset !important;
    width: 65% !important;
  }
}

.product-slider-drag {
  background-color: white !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 32px;
    height: 32px;
  }
}

.swiper-button-next {
  right: 68px;
}

@media screen and (max-width: 768px) {
  .swiper-button-next {
    top: unset;
    bottom: -8px;
    right: 8px;
  }
}

.swiper-button-prev {
  left: 68px;
}
@media screen and (max-width: 768px) {
  .swiper-button-prev {
    top: unset;
    bottom: -8px;
    left: unset;
    right: 56px;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "" !important;
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 768px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    width: 32px;
    height: 32px;
  }
}

.swiper-button-next::after {
  background-image: var(--slider-next);
}

@media screen and (max-width: 768px) {
  .swiper-button-next::after {
    background-image: var(--slider-next-mobile);
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.swiper-button-prev::after {
  background-image: var(--slider-prev);
}

@media screen and (max-width: 768px) {
  .swiper-button-prev::after {
    background-image: var(--slider-prev-mobile);
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.swiper-slide-active > .slide__content {
  display: block;
}
