/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3ee5f8");

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyRegular/font.woff2") format("woff2"),
    url("../fonts/GilroyRegular/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyRegularItalic/font.woff2") format("woff2"),
  url("../fonts/GilroyRegularItalic/font.woff") format("woff");
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyThin/font.woff2") format("woff2"),
  url("../fonts/GilroyThin/font.woff") format("woff");
  font-weight: 100;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyThinItalic/font.woff2") format("woff2"),
  url("../fonts/GilroyThinItalic/font.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyUltraLight/font.woff2") format("woff2"),
  url("../fonts/GilroyUltraLight/font.woff") format("woff");
  font-weight: 200;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyUltraLightItalic/font.woff2") format("woff2"),
  url("../fonts/GilroyUltraLightItalic/font.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyLight/font.woff2") format("woff2"),
  url("../fonts/GilroyLight/font.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyLightItalic/font.woff2") format("woff2"),
  url("../fonts/GilroyLightItalic/font.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyMedium/font.woff2") format("woff2"),
  url("../fonts/GilroyMedium/font.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyMediumItalic/font.woff2") format("woff2"),
    url("../fonts/GilroyMediumItalic/font.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroySemiBold/font.woff2") format("woff2"),
  url("../fonts/GilroySemiBold/font.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroySemiBoldItalic/font.woff2") format("woff2"),
  url("../fonts/GilroySemiBoldItalic/font.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyBold/font.woff2") format("woff2"),
    url("../fonts/GilroyBold/font.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyBoldItalic/font.woff2") format("woff2"),
  url("../fonts/GilroyBoldItalic/font.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyExtraBold/font.woff2") format("woff2"),
  url("../fonts/GilroyExtraBold/font.woff") format("woff");
  font-weight: 800;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyExtraBoldItalic/font.woff2") format("woff2"),
  url("../fonts/GilroyExtraBoldItalic/font.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyBlack/font.woff2") format("woff2"),
  url("../fonts/GilroyBlack/font.woff") format("woff");
  font-weight: 900;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyBlackItalic/font.woff2") format("woff2"),
    url("../fonts/GilroyBlackItalic/font.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyHeavy/font.woff2") format("woff2"),
  url("../fonts/GilroyHeavy/font.woff") format("woff");
  font-weight: 950;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/GilroyHeavyItalic/font.woff2") format("woff2"),
    url("../fonts/GilroyHeavyItalic/font.woff") format("woff");
  font-weight: 950;
  font-style: italic;
}
